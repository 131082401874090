@use 'global' as *;
$logo-height: 55px;

// ---------------------------------------------------------

.logo_train {
  margin-left: auto;
  margin-right: auto;
  z-index: var(--z-index-plus-one);

  h2 {
    color: var(--color-white);
    margin-bottom: 1.5rem;
    text-align: center;

    &.dark {
      color: var(--off-black);
    }
  }

  .button_container {
    margin: 10px auto;
    text-align: center;
  }

  .label_light {
    @include h6;

    color: var(--color-white);
    margin: 0 1rem;
    text-align: center;
    text-wrap: balance;
  }

  .label_dark {
    @include h6;

    color: var(--color-off-black);
    margin: 0 1rem;
    text-align: center;
    text-wrap: balance;
  }

  // ---------------------------------------------------------

  .logo_train_wrapper {
    align-items: center;

    // border: 2px solid blue;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--global-max-width);
    min-height: 70px;
    padding: 20px;
    width: 100%;

    &.logo_train_animated {
      > div {
        &:nth-child(-n + 8) {
          display: flex;
        }
      }
    }
  }

  .logo_container {
    align-items: center;
    display: flex;
    height: $logo-height;
    justify-content: center;
    margin: 10px 0;
    max-width: 180px;
    opacity: 0%;
    width: 50%;

    @include media($small-lowest) {
      width: 25%;
    }

    @include media($medium-highest) {
      width: 16.666%;
    }

    > div {
      display: flex;
      justify-content: center;

      > * {
        align-self: center;
        display: flex;
      }
    }
  }

  .logo_container:first-child:nth-last-child(n + 7),
  .logo_container:first-child:nth-last-child(n + 7) ~ .logo_container {
    @include media($medium-highest) {
      width: 12.5%;
    }
  }

  .logo_train_fade_in {
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
    animation-iteration-count: normal;
    animation-name: fade-in;
    animation-timing-function: ease;
  }

  .logo_train_fade_out {
    animation-duration: 2150ms;
    animation-name: fade-out;
    opacity: 0%;
  }

  &.logo_train_transparent {
    background-color: transparent;
  }

  &.add_vertical_padding {
    padding: 80px 0 40px;
  }
}

.logo_train_image {
  position: relative;

  svg {
    width: 100%;
  }
}

// ---------------------------------------------------------

@keyframes fade-in {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fade-out {
  0% {
    opacity: 100%;
  }

  65% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

// --------------------------------------------------------- Prevents logo walls from taking up too much space on mobile

.hide_on_mobile {
  @include media($small-lowest, down) {
    .logo_container:nth-of-type(2n) {
      display: none;
    }
  }
}

.logos_light {
  svg {
    fill: var(--color-white);
  }
}

.logos_dark {
  svg {
    fill: var(--color-black);
  }
}

.linked_logo {
  transition: transform 150ms ease-out;

  @include hover {
    transform: scale(1.1);
  }
}
