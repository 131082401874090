@use 'global' as *;
.crossfader {
  display: grid;

  div {
    display: grid;
    grid-area: 1 / 1;
    place-content: center;
  }
}

.show_img {
  opacity: 100%;
  transition: opacity 1500ms linear;
}

.hide_img {
  opacity: 0%;
  transition: opacity 2500ms linear;
}
