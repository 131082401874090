@use 'global' as *;
.video_wrapper {
  max-width: var(--global-max-width);

  @include media($small-highest, down) {
    width: 100%;
  }

  &.has_caption {
    @include caption;
  }
}

// ---------------------------------------------------------

.video_container {
  aspect-ratio: 16 / 9;

  iframe {
    background-color: none;
    height: 100% !important;
    width: 100% !important;
  }

  video {
    display: flex;
    width: 100%;
  }
}

.rounded_edges,
.rounded_edges iframe {
  border-radius: var(--global-border-radius);
}

// ---------------------------------------------------------

.video_player {
  left: 0;
  position: absolute;
  top: 0;
}

// --------------------------------------------------------- Video thumbnail

.video_thumbnail_container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  @include hover {
    .video_thumbnail_play_button {
      background-color: var(--color-off-black);
      border: 1.5px solid var(--color-prime-gray);
      cursor: pointer;
      height: 107px;
      width: 107px;

      .video_thumbnail_play_button_icon {
        border-left: 35px solid var(--color-white);
        transform: scale(1.15) translate(16%, 5%);
      }
    }

    .video_thumbnail_play_pill_button {
      background-color: var(--color-off-black);
      color: var(--color-white);

      .video_thumbnail_play_pill_button_icon {
        border-left: 11px solid var(--color-white);
      }
    }
  }
}

.video_thumbnail {
  background-color: var(--color-cement);
  filter: brightness(80%);
  flex: 1;
  height: auto;
  position: relative;
  transition: filter 200ms ease;
  width: 100%;

  @include hover {
    cursor: pointer;
    filter: none;
  }
}

.video_thumbnail_play_button {
  background-color: var(--color-moss);
  border: 1.5px solid var(--color-moss);
  border-radius: 50%;
  color: var(--color-off-black);
  height: 100px;
  pointer-events: none;
  position: absolute;
  transition: all 200ms ease;
  width: 100px;
  z-index: var(--z-index-plus-one);

  .video_thumbnail_play_button_icon {
    border-bottom: 21px solid transparent;
    border-left: 35px solid var(--color-off-black);
    border-top: 21px solid transparent;
    display: inline-block;
    transform: translate(15%, 4%);
    transition: all 200ms ease;
  }
}

.video_thumbnail_play_pill_button {
  background-color: var(--color-amp-yellow);
  border: 2px solid var(--color-off-black);
  border-radius: var(--global-button-border-radius);
  color: var(--color-off-black);
  cursor: pointer;
  font-family: var(--font-family-headings-bold);
  font-size: 18px;
  height: 40px;
  line-height: 26px;
  min-width: 50px;
  padding: 3px 24px 10px;
  pointer-events: none;
  position: absolute;
  text-decoration: none;
  transition: background-color 250ms ease, color 250ms ease;
  word-wrap: no-wrap;
  z-index: var(--z-index-plus-one);

  .video_thumbnail_play_pill_button_icon {
    border-bottom: 6px solid transparent;
    border-left: 11px solid var(--color-off-black);
    border-top: 7px solid transparent;
    display: inline-block;
    transform: translate(7px, 4%);
    transition: all 200ms ease;
  }
}
