@use 'global' as *;
// Approach to control svg size and margin

.bullet_list {
  padding: 0;

  li {
    &:first-child {
      margin-top: 0;
    }

    align-items: baseline;
    display: flex;
    line-height: 1.5;
    margin-top: 0.5rem;
  }
}

.svg_wrapper {
  margin-right: 8px;

  > div {
    background-repeat: no-repeat;
    display: inline-block;
    height: 22px;
    width: 22px;

    svg {
      margin-top: -3px;
    }
  }
}

.svg {
  &_off-black {
    svg {
      fill: var(--color-off-black);
    }
  }

  &_limoncello {
    svg {
      fill: var(--color-limoncello);
    }
  }

  &_prime-green {
    svg {
      fill: var(--color-prime-green);
    }
  }

  &_prime-red {
    svg {
      fill: var(--color-prime-red);
    }
  }

  &_lagoon {
    svg {
      fill: var(--color-lagoon);
    }
  }
}
