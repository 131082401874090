@use 'global' as *;
.container {
  font-family: var(--font-family-headings);

  .dropdown_label {
    color: var(--color-off-black);
    font-family: var(--font-family-headings-bold);
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.dropdown_selector {
  cursor: pointer;
  display: inline-block;
  min-width: 200px;
  position: relative;

  .selector {
    border-bottom: 1px solid var(--color-off-black);
    color: var(--color-off-black);
    font-size: 22px;
    margin-top: 7px;
    padding: 5px 0;
    padding-right: 30px;

    svg {
      height: 10px;
      position: absolute;
      right: 5px;
      top: 20px;
      transition: transform 0.3s;
      width: 10px;
    }

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .options {
    background-color: var(--color-white);
    border-radius: 5px;
    height: auto;
    margin-top: 8px;
    max-height: 0;
    opacity: 0%;
    overflow: hidden;
    position: absolute;
    top: 40px;
    transition: opacity 0.3s;
    width: 100%;
    z-index: 1;

    &.open {
      border: 1px solid var(--color-pebble);
      max-height: 250px;
      opacity: 100%;
      overflow: auto;
    }

    > div {
      padding: 9px 15px;

      @include hover {
        background-color: var(--color-warm-gray);
      }
    }
  }
}

.options::-webkit-scrollbar {
  appearance: none;
  width: 7px;
}

.options::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 50%);
  border-radius: 4px;
  box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}

.full_width {
  width: 100%;
}

.no_results_message {
  display: flex;
}
