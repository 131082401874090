@use 'global' as *;
.job {
  display: flex;
  margin: 40px 0;

  @include media($medium-lowest) {
    margin-top: 80px;
  }

  iframe {
    box-sizing: border-box;
    margin: 0 auto;
    width: 956px;
  }
}
