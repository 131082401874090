@use 'global' as *;
.calculator {
  background-color: var(--color-white);
  border-radius: var(--global-border-radius);
  box-shadow: var(--global-box-shadow);
  color: var(--color-off-black);
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem 2.5rem 0;
  width: clamp(300px, 100%, var(--global-max-width));

  @include media($small-highest, down) {
    border-radius: 0 0 var(--global-border-radius) var(--global-border-radius);
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  h5 {
    font-size: 1em;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  sup {
    font-size: 0.8em;
  }
}

.gray_text {
  color: var(--color-pebble);
}

.tab_selector {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.tab {
  display: flex;
  justify-content: center;
  width: max-content;

  h5 {
    color: var(--color-white);
    cursor: pointer;
    margin-bottom: 0;
    padding: 1rem 1.5rem;
  }
}

.active_tab {
  background-color: var(--color-white);
  border-radius: 12px 12px 0 0;

  h5 {
    color: var(--color-off-black);
  }
}

.calc_left,
.calc_right {
  flex: 1;

  @include media($small-highest) {
    padding: 2rem;
  }
}

.calc_left {
  max-width: 700px;
  width: 100%;
}

.calc_right {
  margin-bottom: 1rem;
  width: fit-content;

  @include media($small-highest) {
    border-left: 1px solid var(--color-prime-gray);
    padding-left: 3rem;
  }
}

.user_input_group {
  margin-bottom: 3rem;
}

.step_number {
  align-items: center;
  background-color: var(--color-prime-teal);
  border-radius: 50%;
  display: flex;
  font-size: 0.8em;
  font-weight: 800;
  height: 1.8em;
  justify-content: center;
  margin-bottom: 0.3rem;
  width: 1.8em;
}

.benchmark_container {
  position: relative;
  width: fit-content;

  p {
    left: -49.3%;
    position: relative;
    text-align: center;
  }

  p::before {
    background-color: var(--color-pebble);
    content: '';
    display: block;
    height: 12px;
    left: 50%;
    margin-bottom: -5px;
    position: relative;
    width: 2px;
  }
}

.results_panel {
  background-color: var(--color-off-black);
  border-radius: var(--global-border-radius);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem 1.5rem;

  h4 {
    margin-bottom: 0.7rem;
  }

  p {
    border-bottom: 1px var(--color-cement) solid;
    font-size: 1.1em;
    margin-bottom: 3rem;
    padding-bottom: 3rem;

    @include media($medium, down) {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  h6 {
    color: var(--color-prime-gray);
    font-size: 0.7em;
    margin-bottom: 0.3rem;
  }
}

.result {
  color: var(--color-white);
  display: block;
  font-family: var(--font-family-headings);
  font-size: 1.4em;
  margin-bottom: 1.5rem;
}

.amperity_result_container {
  display: flex;
  justify-content: space-between;

  .amperity_result {
    color: var(--color-amp-yellow);
    font-family: var(--font-family-headings-bold);
    font-size: 2em;
    font-weight: 800;

    @include media($medium, down) {
      font-size: 1.5em;
      margin-bottom: 0.5rem;
    }
  }
}

// ======================== Input styling

.input_field {
  background-color: var(--color-cloud);
  border: 1px solid rgb(0 0 0 / 0%);
  border-radius: var(--global-border-radius);
  font-family: var(--font-family-headings);
  font-size: 16px;
  margin: 0.5rem auto 0;
  padding: 10px 16px;
  transition: background-color 0.3s ease;
  width: 100%;

  &:focus {
    background-color: var(--color-white);
    border: 1px solid var(--color-moss);
    margin: 6px auto 0;
  }
}

// ======================== Slider styling

.horizontal_slider {
  height: 30px;
  max-width: 700px;
  width: 100%;
}

.thumb {
  background: var(--color-lagoon);
  border-radius: 100%;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  cursor: pointer;
  display: block;
  overflow: visible;
  position: absolute;
  word-break: keep-all;
  z-index: 100;
}

.thumb.active {
  background-color: grey;
}

.track {
  background: var(--color-prime-gray);
  border-radius: 25px;
  overflow: hidden;
  position: relative;

  &:first-of-type {
    background: linear-gradient(
      90deg,
      hsl(188deg 100% 15%) 0%,
      hsl(188deg 96% 17%) 10%,
      hsl(188deg 92% 19%) 20%,
      hsl(188deg 88% 21%) 30%,
      hsl(188deg 84% 23%) 40%,
      hsl(188deg 81% 25%) 50%,
      hsl(188deg 82% 27%) 60%,
      hsl(188deg 83% 29%) 70%,
      hsl(187deg 84% 31%) 80%,
      hsl(187deg 86% 33%) 90%,
      hsl(187deg 47% 66%) 100%
    );
  }
}

.horizontal_slider .track {
  height: 6px;
  top: 20px;
}

.horizontal_slider .thumb {
  height: 17px;
  line-height: 38px;
  outline: none;
  top: 14px;
  width: 17px;
}

.footnotes {
  color: var(--color-cloud);
  display: flex;
  font-size: 0.9em;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 2rem;

  @include media($small-highest, down) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  strong {
    @include media($medium-lowest) {
      &::after {
        content: ': ';
      }
    }

    @include media($small-highest, down) {
      display: block;
    }
  }
}
