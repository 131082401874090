@use 'global' as *;
.desktop_image {
  display: none;

  @include media($small) {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: var(--global-max-width);
    width: 100%;
  }
}

.mobile_image {
  display: block;
  height: auto;
  width: 100%;

  @include media($small) {
    display: none;
  }
}
