@use 'global' as *;
.tags_container {
  margin-bottom: 10px;
  width: 100%;

  span,
  a {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  span {
    background-color: var(--color-cement);
    border-radius: 5px;
    color: var(--color-off-black);
    display: inline-block;
    font-family: var(--font-family-headings-bold);
    font-size: 11px;
    font-weight: var(--font-weight-medium);
    letter-spacing: 1.6px;
    line-height: 2;
    margin-bottom: 8px;
    padding: 2px 8px;
    text-transform: uppercase;
    transition: background-color 150ms;
    white-space: nowrap;

    @include hover {
      cursor: text;
    }
  }

  a span {
    background-color: var(--color-cement);

    @include hover {
      background-color: var(--color-limoncello);
      cursor: pointer;
    }
  }
}

.theme_light {
  a span {
    background-color: var(--color-white);
    color: var(--color-anvil);
    transition: background-color 150ms;

    @include hover {
      cursor: pointer;
    }
  }
}
