@use 'global' as *;
$card-width: 650px;

// ---------------------------------------------------------

.customer_card {
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--global-border-radius);
  box-shadow: var(--global-box-shadow);
  color: var(--color-off-black);
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc($card-width / 3.5), 1fr));
  height: auto;
  margin: 0 auto;
  max-width: $card-width;
  min-height: 350px;
  text-align: center;
  transform: translateY(-0.5rem);
  transition: transform 300ms;
  width: 100%;

  &.active,
  &:hover {
    box-shadow: var(--global-box-shadow-hover);
    transform: translateY(-1rem);
  }

  h4,
  p,
  span {
    cursor: pointer;
  }

  @include media($small-lowest) {
    text-align: left;
  }
}

// --------------------------------------------------------- Left column

.card_main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 2rem;

  // Temp fix for when two Customer Cards are in a default width 2-col Grid
  @include media($small-highest, $medium-lowest) {
    padding: 0.5rem;
  }
}

// --------------------------------------------------------- Right column

.card_image_description,
.card_text_description {
  display: none;

  @include media($small-lowest) {
    align-items: center;
    border-radius: 0 8px 8px 0;
    display: flex;
    height: 100%;
    overflow: hidden;
  }
}

.card_image_description {
  background-color: var(--color-off-black);
}

.card_text_description {
  background-color: var(--color-cement);

  p {
    @include h4;

    margin: 1.5rem;
  }
}
