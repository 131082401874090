@use 'global' as *;
// ---------------------------------------------------------

@mixin navigation-arrow {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  transition: background-color 150ms ease, color 150ms ease;

  @include hover {
    cursor: pointer;
  }
}

// ---------------------------------------------------------

.carousel_wrapper {
  width: 100%;

  :global(.swiper-pagination-bullet) {
    background-color: var(--color-ocean);
    height: 10px;
    left: 0;
    width: 10px;
  }
}

// ---------------------------------------------------------

.swipe_container {
  margin: auto;
  max-width: var(--global-max-width);
  position: relative;
  width: 90%;

  &.is_quote {
    > div > div:first-of-type {
      margin-bottom: 1rem;
    }

    @include media($small-lowest, down) {
      left: 1rem;
      margin: 0;
      width: calc(100% - 1rem);
    }
  }

  &.is_card {
    @include media($small-lowest, down) {
      left: 1rem;
      margin: 0;
      width: calc(100% - 1rem);
    }
  }

  &.is_content_carousel {
    height: auto;
    left: 1rem;
    margin: 0;
    width: calc(100% - 1rem);

    @include media($medium) {
      margin-left: -8px;
      max-height: 60vh;
      min-height: 500px;
    }
  }
}

.swipe_item {
  margin: 10px 0;
  overflow: visible;
  transform-style: preserve-3d;

  &.is_quote {
    height: auto;

    .swipe_child {
      display: flex;
      height: 100%;

      > div {
        margin: auto;
      }
    }
  }

  &.is_card {
    height: auto;

    .swipe_child {
      align-items: stretch;
      border: 3px solid transparent;
      display: flex;
      height: 100%;
    }
  }

  &.is_content_carousel {
    display: grid;
    height: auto;

    .swipe_child {
      display: grid;

      a {
        height: 100%;
      }
    }

    @include media($medium) {
      left: 5px;
    }
  }
}

// --------------------------------------------------------- Carousel buttons

.buttons_container {
  display: none;

  @include media($small-lowest) {
    display: inline-block;
    height: 50px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 100vw;
    z-index: var(--z-index-plus-three);

    &.is_quote,
    &.is_card {
      max-width: var(--global-max-width);
      position: absolute;
      top: 35%;
    }

    &.is_content_carousel {
      left: 55px;
      position: relative;
      width: 100px;
    }
  }
}

.navigation_button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 25px;
  position: absolute;

  span {
    @include navigation-arrow;

    .is_card &,
    .is_quote & {
      $size: 30px;

      background-color: var(--color-amp-yellow);
      color: var(--color-off-black);
      height: $size;
      width: $size;
    }

    &:hover {
      background-color: var(--color-amp-yellow) !important;
      color: var(--color-off-black) !important;
    }

    .is_content_carousel & {
      $size: 40px;

      background: transparent !important;
      border: 2px solid var(--color-ocean);
      color: var(--color-ocean) !important;
      height: $size;
      width: $size;

      &:hover {
        background: transparent;
        border: 2px solid var(--color-prime-teal);
        color: var(--color-prime-teal) !important;
      }
    }
  }

  svg {
    $size: 15px;

    align-self: center;
    display: flex;
    height: $size;
    width: $size;

    .is_content_carousel & {
      $size: 20px;

      height: $size;
      width: $size;
    }
  }
}

.button_previous {
  left: 0%;

  svg {
    transform: rotate(180deg);
  }
}

.button_next {
  right: 0%;
}

// ---------------------------------------------------------

.theme_light {
  :global(.swiper-pagination-bullet) {
    background-color: var(--color-prime-teal) !important;
  }

  .is_content_carousel .navigation_button span {
    border: 2px solid var(--color-prime-teal);
    color: var(--color-prime-teal) !important;

    @include hover {
      border: 2px solid var(--color-ocean);
      color: var(--color-ocean) !important;
    }
  }
}

.theme_dark {
  .navigation_button span {
    background-color: var(--color-off-black);
    color: var(--color-white);

    @include hover {
      background-color: var(--color-anvil);
    }
  }
}

// ---------------------------------------------------------

.hidden {
  display: none;
}
