@use 'global' as *;
// -------------------------------------------------------- Grid content

.grid_content {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  height: auto;
  justify-content: flex-start;
  width: 100%;
  z-index: var(--z-index-plus-two);

  @include media($medium) {
    gap: calc(var(--global-grid-gutter) * 1.5);
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;

    @include media($small-highest) {
      margin-bottom: 4rem;
    }
  }

  &.is_uneven {
    @include media($medium) {
      &.image_right {
        grid-template-columns: 2fr 1.5fr !important;
      }

      grid-template-columns: 1.5fr 2fr !important;
    }
  }
}

// -------------------------------------------------------- Image

.image_right {
  @include media($medium) {
    .feature_image {
      order: 2;
    }
  }
}

// -------------------------------------------------------- Text & logo

.content {
  width: 100%;

  h2,
  h3 {
    text-wrap: balance;
  }
}

.logo_container {
  margin-top: 1rem;
}

// -------------------------------------------------------- Individual feature cards (2-4)

.content_blocks {
  display: grid;
  gap: calc(var(--global-grid-gutter) / 1.5);
  margin-top: 1.5rem;

  a {
    margin-bottom: 0;
  }

  p:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
}

.one_col {
  grid-template-columns: 1fr;
}

.two_col {
  grid-template-columns: 1fr;

  @include media($small-highest) {
    grid-template-columns: 1fr 1fr;
  }
}
