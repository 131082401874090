@use 'global' as *;
.stat_container {
  cursor: text;
  display: inline-block;
  position: relative;

  &::after {
    bottom: 8px;
    content: '';
    display: block;
    height: 1.8rem;
    position: absolute;
    width: 100%;
    z-index: var(--z-index-minus-one);
  }

  .stat_value {
    @include display;

    position: relative;
  }

  &:hover {
    &::after {
      height: 4.4rem;
      transition: all 0.2s ease-in-out;
    }
  }
}

.stat_description {
  @include h4;

  cursor: text;
  margin: 1rem 0;
}

.theme {
  &_highlight_yellow {
    .stat_container::after {
      background-color: var(--color-limoncello);
    }
  }

  &_highlight_teal {
    color: var(--color-white);

    .stat_container::after {
      background-color: var(--color-ocean);
    }
  }
}
