@use 'global' as *;
.accordion_classes {
  color: var(--color-off-black);
  padding: 2rem 0;

  @include media($medium-lowest) {
    padding: 2rem 20px;
  }
}

.light_text {
  color: var(--color-white);
}

.is_rounded {
  border-radius: 10px;

  > div > div:nth-of-type(2) > div {
    border-radius: 10px 10px 0 0;
  }
}
