@use 'global' as *;
// ---------------------------------------------------------

.calendly_container {
  display: inline-block;
  height: 695px;
  margin: 1rem 0;
  min-width: 320px;
  width: 100%;

  @include media($small) {
    margin: 0;
  }
}
