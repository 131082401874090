@use 'global' as *;
.container {
  align-items: center;
  display: flex;

  iframe {
    height: 80px;
  }
}

.pingpong {
  margin: 0;
  padding: 1rem 2rem;

  @include media($medium-lowest) {
    aspect-ratio: 16/9;
    background-color: var(--color-off-black);
  }

  iframe {
    @include media($small-highest) {
      height: 232px;
    }

    @include media($medium-lowest) {
      height: 360px;
    }
  }
}

.embedded {
  height: 80px;
  margin: 1.5rem 0;
  width: 100%;
}
