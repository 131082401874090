@use 'global' as *;
// ---------------------------------------------------------

.quote_container {
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
}

// ---------------------------------------------------------

.quote_text {
  font-family: var(--font-family-headings);
  margin: 1rem;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  z-index: var(--z-index);

  > div:first-of-type {
    margin-bottom: 1rem;
  }

  @include media($small) {
    max-width: var(--global-max-width);
    padding: 80px 40px;
  }

  @include media($medium-lowest) {
    max-width: calc((var(--global-max-width) / 3) * 2);
  }
}

.font {
  &_h1 {
    @include h1;
  }

  &_h2 {
    @include h2;
  }

  &_h3 {
    @include h3;
  }

  &_h4 {
    @include h4;
  }

  &_h5 {
    @include h5;
  }

  &_label {
    @include h6;
  }

  &_paragraph {
    color: var(--global-off-black);
    font-family: var(--font-family-base);
    font-size: var(--font-size-base);
    font-weight: var(--font-weight-regular);
    line-height: 1;
  }

  &_default {
    &_large {
      @include h2;
    }

    &_small {
      @include h4;

      font-family: var(--font-family-headings) !important;
    }
  }
}

// ---------------------------------------------------------

.quote_attribution {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include media($small-lowest) {
    white-space: pre;
  }

  .byline {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include media($small-lowest) {
      align-items: flex-start;
    }
  }

  .byline_centered {
    text-align: center;
    width: 100%;

    p {
      width: inherit;
    }
  }

  p {
    font-family: var(--font-family-headings);
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
    line-height: 1.4;
    margin-bottom: 0;
    width: fit-content;

    &::first-line {
      font-family: var(--font-family-headings-bold);
      font-weight: var(--font-weight-medium);
      line-height: 1.8;
    }
  }

  @include media($small-lowest) {
    flex-direction: row;

    .byline {
      text-align: left;
      width: auto;
    }
  }

  .logo_container {
    align-self: center;
    margin-top: 0.5rem;
    max-width: 150px;

    @include media($small-lowest) {
      display: block;
      margin-right: 1rem;
      min-height: 50%;
      width: 150px;

      p {
        text-align: left;
      }
    }
  }

  .quote_profile_image {
    align-self: center;
    margin-top: 0.5rem;

    @include media($small-lowest) {
      margin-right: 1.5rem;
      margin-top: 0;
    }
  }

  .quote_button {
    margin-top: 20px;
  }
}
