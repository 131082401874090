@use 'global' as *;
// ---------------------------------------------------------

.rating_small {
  padding: 10px 0;
}

.rating_default {
  padding: 40px 0;
}

// ---------------------------------------------------------

.rating_default_content {
  align-items: center;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: $medium;
  padding: 0 1rem;

  @include media($small) {
    grid-template-columns: 3fr 5fr;
  }

  @include media($medium) {
    padding: 0;
  }

  h5 {
    color: var(--color-off-black);
  }

  > div {
    margin: 0 auto;
  }

  .rating_image {
    margin-bottom: 1rem;
  }
}

.dark_theme {
  h5 {
    color: var(--color-limoncello);
  }
}

// ---------------------------------------------------------

.rating_small_content {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 0 1rem;
  text-align: center;

  @include media($small-lowest) {
    flex-direction: row;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .rating_image {
    margin: 0;
  }
}

// ---------------------------------------------------------

.badge_image {
  max-width: 400px;
  width: 100%;
}

.rating_image {
  max-width: 200px;
}
