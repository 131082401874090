@use 'global' as *;
.hero_container {
  align-items: center;
  background-image: linear-gradient(
    180deg,
    hsl(0deg 0% 5%) 0%,
    hsl(192deg 22% 9%) 20%,
    hsl(191deg 38% 12%) 40%,
    hsl(190deg 52% 14%) 60%,
    hsl(189deg 70% 16%) 80%,
    hsl(188deg 100% 17%) 100%
  );
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @include media($medium-highest) {
    gap: 50px;
    min-height: 800px;
  }
}

.hero_wrapper {
  align-items: center;
  display: grid;
  gap: 0;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  max-width: var(--global-max-width);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);

  @include media($small, $medium) {
    grid-template-columns: 1.5fr 1fr;
  }

  @include media($medium) {
    grid-template-columns: 1fr 1fr;
  }

  @include media(1460px) {
    padding: 0;
  }
}

.hero_content {
  .hero_header {
    margin: 0;

    @include h1-homepage;
  }

  .hero_subheader {
    font-family: var(--font-family-headings-regular);
    font-size: rfs(1rem);

    @include media($small) {
      margin-right: 1rem;
    }

    @include media($medium-lowest) {
      font-size: rfs(1.2rem);
      text-wrap: balance;
    }
  }

  .buttons_container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @include media($x-small) {
      flex-direction: row;
    }
  }
}

.hero_image {
  width: calc(100% + 3rem);

  @include media($small, down) {
    margin-left: -14px;
    margin-top: 1rem;
  }

  @include media($small, $medium) {
    height: 100%;
    margin-left: 0;
    margin-top: 0;
    width: calc(100% + 3rem);

    img {
      height: 100%;
      object-fit: cover !important;
      object-position: 3% 20%;
    }
  }

  @include media($large, $x-large-lowest) {
    max-width: 850px;
    position: absolute;
    right: 0;
    top: 50px;
    width: calc(100vw / 2);
    z-index: 0;
  }
}

// ---------------------------------------------------------

.hero_subheader {
  font-family: var(--font-family-headings);
}

.flip_text_cube {
  display: inline-block;
  height: 80px;
  margin-top: -14px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 400ms ease-in-out;
  width: 100%;

  @include media($small) {
    margin-top: 0;
  }
}

.flip_text {
  align-items: center;
  backface-visibility: hidden;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    var(--color-prime-teal),
    var(--color-amp-yellow),
    var(--color-amp-yellow)
  );
  color: transparent;
  display: flex;
  height: 90px;
  justify-content: flex-start;
  min-width: 500px !important;
  overflow: visible;
  position: absolute;
  transform-origin: center center;
  width: 100%;

  &:nth-of-type(1) {
    transform: rotateX(0deg) translateZ(40px); /* Front face */
  }

  &:nth-of-type(2) {
    transform: rotateX(90deg) translateZ(40px); /* Top face */
  }

  &:nth-of-type(3) {
    transform: rotateX(180deg) translateZ(40px); /* Back face */
  }

  &:nth-of-type(4) {
    transform: rotateX(270deg) translateZ(40px); /* Bottom face */
  }
}

// ---------------------------------------------------------

.logo_train_container {
  @include media($large, $x-large-lowest) {
    transform: translateY(5rem);
  }

  svg {
    path {
      fill: var(--color-ice) !important;
    }
  }
}
