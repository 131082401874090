@use 'global' as *;
.container {
  display: flex;
  justify-content: center;
  max-height: 400px;
  padding-top: 2rem;
  position: relative;
}

.base_layer {
  svg {
    max-height: 400px;
    max-width: 400px;

    @include media($small-highest, down) {
      height: 300px;
      max-height: 100%;
      max-width: 85%;
      padding-left: 1rem;
    }
  }
}

.cloud,
.cloud_2 {
  position: absolute;

  svg {
    max-height: 120px;
    max-width: 175px;
  }
}

.cloud {
  animation: left-right-loop 8000ms ease-in-out both;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  top: 1%;
}

.cloud_2 {
  animation: right-left-loop 5500ms ease-in-out both;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  left: 20%;
  top: 68%;
}

@keyframes left-right-loop {
  0% {
    transform: translateX(80%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes right-left-loop {
  0% {
    transform: translateX(-20%);
  }

  100% {
    transform: translateX(50%);
  }
}
