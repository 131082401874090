// ---------------------------------------------------------

@use 'support' as *;

// ---------------------------------------------------------

@mixin default-button {
  color: var(--color-off-black);
  cursor: pointer;
  font-family: var(--font-family-headings-bold);
  font-size: 16px;
  line-height: 26px;
  text-decoration: none;
  transition: background-color 250ms ease, color 250ms ease;
  word-wrap: no-wrap;
}

// ---------------------------------------------------------

@mixin pill-button {
  @include default-button;

  border-radius: var(--global-button-border-radius);
  padding: 5px 20px 8px;

  &:disabled {
    background-color: var(--color-prime-gray);
    color: var(--color-slate);

    @include hover {
      background-color: var(--color-prime-gray);
      color: var(--color-slate);
    }
  }
}

// ---------------------------------------------------------

@mixin arrow($color: var(--color-lagoon), $hover: var(--color-dusk)) {
  color: $color;
  cursor: pointer;
  font-family: var(--font-family-headings-bold);
  font-size: 16px;
  line-height: 26px;
  padding-bottom: 10px;
  position: relative;
  text-decoration: none !important;
  transition: background-color 250ms ease;
  word-wrap: no-wrap;

  &::before {
    background: $color;
    content: '';
    height: 1.5px;
    margin-top: 18px;
    position: absolute;
    top: 35.5%;
    transition: width 300ms ease;
    width: 55px;
  }

  &::after {
    border-right: 1.5px solid $color;
    border-top: 1.5px solid $color;
    content: '';
    float: left;
    height: 7px;
    left: 48px;
    margin-top: 28px;
    position: absolute;
    transform: rotate(45deg);
    transition: all 300ms ease;
    width: 7px;
  }

  &:hover {
    color: $hover;

    &::before {
      animation-name: expand-right;
      background: $hover;
      width: 115px;
    }

    &::after {
      animation-name: move-right;
      border-right: 1.5px solid $hover !important;
      border-top: 1.5px solid $hover !important;
      transform: translate(60px, 0) rotate(45deg);
    }
  }

  @keyframes expand-right {
    from {
      width: 55px;
    }

    to {
      width: 115px;
    }
  }

  @keyframes move-right {
    from {
      transform: translate(0, 0) rotate(45deg);
    }

    to {
      transform: translate(60px, 0) rotate(45deg);
    }
  }
}
